.stratego {
  display: flex;
  flex-flow: row;
  align-content: stretch;
  justify-content: center;
  background-color: #f1f7ed;
  min-height: 100vh;
  padding: 30px;
}

.game {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.game-history {
  margin-left: 16px;
  min-width: 25%;
}

.setup {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  min-width: 560px; /* match gameboard */
  max-width: 40%;
  background-color: #7ca982;
  padding: 6px;
  min-height: 45px;
}

.game-board {
  padding: 30px;
  background-color: #799496;
  align-self: center; /* this won't be right */
  display: flex;
}

.row {
  flex: row;
}

.grid {
  border: 2px solid black;
  margin-top: -2px;
  margin-left: -2px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.piece {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  border: 1px solid black;
  /* background-color: rgba(0, 0, 0, 0.4); */
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected {
  border: 2px solid black;
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  .stratego {
    flex-flow: column;
  }

  .game-history {
    margin-left: 0px;
    margin-top: 8px;
  }

  .game-board {
    padding: 10px;
  }

  .setup {
    min-width: 320px; /* same as gameboard  25*10 + 10*2*/
  }

  .grid {
    border: 1px solid black;
    margin-top: -1px;
    margin-left: -1px;
    width: 30px;
    height: 30px;
  }

  .piece {
    height: 25px;
    width: 25px;
  }

  .selected {
    border: 1.5px solid black;
  }
}

.player1 {
  background-color: rgba(255, 0, 0, 0.5);
}

.player2 {
  background-color: rgba(0, 0, 255, 0.5);
}

.immovable {
  background-color: #243e36;
}
